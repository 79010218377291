import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "./PrimaryButton";
import { StandardTextField } from "./StandardTextField";
import { FC, useMemo, useState } from "react";

interface SearchFormProps {
  onClick: (dni: string) => Promise<void>;
}

export const SearchForm: FC<SearchFormProps> = ({
  onClick,
}: SearchFormProps) => {
  const [dni, setDni] = useState<string>("");

  return (
    <>
      <Typography variant="h4">¡Bienvenido!</Typography>
      <div style={{ color: "#5F616E", fontSize: 17, textAlign: "center" }}>
        En este apartado podrás descargar tus certificados
      </div>
      <br />
      <Box component="form" noValidate sx={{ mt: 2 }}>
        <StandardTextField
          variant="standard"
          margin="normal"
          fullWidth
          id="dni"
          placeholder="Ingresa tu DNI"
          name="dni"
          autoComplete=""
          autoFocus
          value={dni}
          onChange={(v) => setDni(v.target.value)}
        />
        <PrimaryButton
          fullWidth
          variant="contained"
          className="Button"
          onClick={() => onClick(dni)}
        >
          Buscar
        </PrimaryButton>
      </Box>
    </>
  );
};

export const MoreCourses = ({
  isSearched,
  total,
}: {
  isSearched: boolean;
  total?: number;
}) => {
  const message = useMemo(() => {
    if (!isSearched) return "Descubre todos los cursos por nuestro canal";
    switch (total) {
      case 0:
        return (
          <>
            <strong style={{ color: "red" }}>¡Es hora de comenzar!</strong>
            <p>No cuentas con certificación, descubre nuestros cursos:</p>
          </>
        );
      case 1:
        return (
          <>
            <strong>¡Felicitaciones!</strong>
            <p>sigue preparándote, descubre más cursos en nuestro canal:</p>
          </>
        );
      case 2:
      case 3:
      case 4:
        return (
          <>
            <strong>¡Excelente!</strong>
            <p>
              sigue incrementando tus conocimientos, descubre otros cursos que
              te ayudarán:
            </p>
          </>
        );
      default:
        return (
          <>
            <strong>¡Imparable!</strong>
            <p>tu formación está garantizada, descubre nuevos cursos:</p>
          </>
        );
    }
  }, [total, isSearched]);

  return (
    <Box
      style={{
        textAlign: "center",
        borderTop: "1px dashed #DEDEDE",
        padding: 30,
        fontStyle: "italic",
        maxWidth: 520,
      }}
    >
      <div>{message}</div>
      <br />
      <a
        target="_blank"
        rel="noreferrer"
        href="https://whatsapp.com/channel/0029VaDySzwFHWq0sH7IvR12"
      >
        <img
          width="50"
          alt="canal de whatsapp"
          src="./icons/whatsapp-channel-icon.png"
        />
      </a>
    </Box>
  );
};
