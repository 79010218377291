import React, { MouseEvent, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Checkbox,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Autocomplete,
  Select,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PaidIcon from "@mui/icons-material/Paid";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SaveIcon from "@mui/icons-material/Save";
import { Student } from "../../../types/slices/studentType";
import { useCycleStore } from "../../../hooks/useCycleStore";
import { Label } from "@mui/icons-material";

const CustomTable = (props: any) => {
  const {
    columns = [],
    rows = [],
    loading = false,
    hasOptions,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;

  const { programs, getProgram } = useCycleStore();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //icon btn
  const [openMenu, setOpenMenu] = React.useState<any>(null);
  const handleOpen = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const navigate = useNavigate();

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  // };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      color: theme.palette.common.black,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  useEffect(() => {
    if (!programs.length && props.onSearchByOption) {
      getProgram();
    }
  }, [programs, getProgram, props]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElFormat, setAnchorElFormat] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorEl);
  const openFormat = Boolean(anchorElFormat);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (side?: string) => {
    setAnchorEl(null);
    if (side) {
      props.onPrintMultipleCertificate(side, "print");
    }
  };
  const handleFormatClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElFormat(event.currentTarget);
  };
  const handleFormatClose = (side?: string) => {
    setAnchorElFormat(null);
    if (side) {
      props.onPrintMultipleCertificate(side, "format");
    }
  };

  return (
    <>
      <Grid container>
        {!props?.disabled_title && (
          <Grid
            item
            container
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              component="h1"
              gutterBottom
              color="#003c84"
              className="tittle"
              sx={{ mt: 1, ml: 1, fontWeight: 600 }}
            >
              {props.title && props.title.toUpperCase()}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          {props.onSearch &&
            !!!(props.onDownloadExcel && props.onUploadExcel) && (
              <Grid item>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: 350 }}
                  onChange={(e) => props.onSearch(e.target.value)}
                  placeholder="Buscar..."
                  size="small"
                />
              </Grid>
            )}
          {props.onSearch && props.onDownloadExcel && props.onUploadExcel && (
            <Grid item xs={12}></Grid>
          )}
          {props.onAdd && (
            <Grid item xs={!props.onSearch && 12} sx={{ textAlign: "right" }}>
              <Button
                onClick={() => navigate({ pathname: props.onAdd })}
                sx={{
                  border: "solid 1px #73B2FF",
                  mt: "10px",
                  color: "#73B2FF",
                  "&:hover": {
                    bgcolor: "#73B2FF",
                    color: "#fff",
                  },
                }}
              >
                {"Agregar"}
              </Button>
            </Grid>
          )}
          {props.onDownloadExcel && props.onUploadExcel && (
            <>
              <Grid item sx={{ textAlign: "right" }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: 200, marginTop: 1, marginRight: 2 }}
                  onChange={(e) => props.onSearch(e.target.value)}
                  placeholder="Buscar..."
                  size="small"
                />
                {props.filterBySentPrint && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.filterBy}
                    label="Filtro por Enviados"
                    onChange={(event) =>
                      props.filterBySentPrint(event.target.value)
                    }
                    sx={{ marginTop: 1, marginRight: 2 }}
                    size="small"
                  >
                    <MenuItem value={"all"}>Todos</MenuItem>
                    <MenuItem value={"print"}>Solo Fisicos</MenuItem>
                    <MenuItem value={"0"}>No Enviados</MenuItem>
                    <MenuItem value={"1"}>Enviados</MenuItem>
                  </Select>
                )}
                <Button
                  onClick={() => props.onDownloadExcel()}
                  sx={{
                    border: "solid 1px #73B2FF",
                    color: "#73B2FF",
                    "&:hover": {
                      bgcolor: "#73B2FF",
                      color: "#fff",
                    },
                  }}
                >
                  {"Data"}
                </Button>
                <Button
                  onClick={() => props.onDownloadTemplate()}
                  sx={{
                    border: "solid 1px #73B2FF",
                    color: "#73B2FF",
                    "&:hover": {
                      bgcolor: "#73B2FF",
                      color: "#fff",
                    },
                  }}
                >
                  {"Certificado"}
                </Button>
                <Button
                  onClick={() => props.onUploadExcel()}
                  sx={{
                    border: "solid 1px #73B2FF",
                    ml: 2,
                    color: "#73B2FF",
                    "&:hover": {
                      bgcolor: "#73B2FF",
                      color: "#fff",
                    },
                  }}
                >
                  {"Cargar Data"}
                </Button>
              </Grid>
            </>
          )}
          {props.onSearchByOption && (
            <Grid item sx={{ textAlign: "right" }} xs={3}>
              <Autocomplete
                disablePortal
                id="combo-box-course"
                placeholder="Escoge un programa"
                options={programs || []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option?.name === value?.name
                }
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} size="small" placeholder="Programa" />
                )}
                onChange={(e, newValue) => props.onSearchByOption(newValue)}
              />
            </Grid>
          )}
          {props.onAddFn && (
            <Grid
              item
              xs={
                !props.onSearch &&
                !props.onDownloadExcel &&
                !props.onSearchByOption &&
                12
              }
              sx={{ textAlign: "right" }}
            >
              <Button
                onClick={() => props.onAddFn()}
                sx={{
                  border: "solid 1px #73B2FF",
                  color: "#73B2FF",
                  "&:hover": {
                    bgcolor: "#73B2FF",
                    color: "#fff",
                  },
                }}
                disabled={props.disabledAdd ? props.disabledAdd : false}
              >
                {props.onAddFnName ? props.onAddFnName : "Agregar"}
              </Button>

              {props.onPrintMultipleCertificate && (
                <>
                  <Button
                    id="basic-button-print"
                    aria-haspopup="true"
                    onClick={handleClick}
                    sx={{
                      border: "solid 1px #73B2FF",
                      color: "#73B2FF",
                      ml: "8px",
                      "&:hover": {
                        bgcolor: "#73B2FF",
                        color: "#fff",
                      },
                    }}
                  >
                    Sin Formato
                  </Button>
                  <Menu
                    id="basic-menu-print"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose("")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => handleClose("front")}>
                      Solo frontal
                    </MenuItem>
                    <MenuItem onClick={() => handleClose("back")}>
                      Solo posterior
                    </MenuItem>
                    <MenuItem onClick={() => handleClose("both")}>
                      Ambas caras
                    </MenuItem>
                  </Menu>
                  <Button
                    id="basic-button-format"
                    aria-haspopup="true"
                    onClick={handleFormatClick}
                    sx={{
                      border: "solid 1px #73B2FF",
                      color: "#73B2FF",
                      ml: "8px",
                      "&:hover": {
                        bgcolor: "#73B2FF",
                        color: "#fff",
                      },
                    }}
                  >
                    Con Formato
                  </Button>
                  <Menu
                    id="basic-menu-format"
                    anchorEl={anchorElFormat}
                    open={openFormat}
                    onClose={() => handleFormatClose("")}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => handleFormatClose("front")}>
                      Solo frontal
                    </MenuItem>
                    <MenuItem onClick={() => handleFormatClose("back")}>
                      Solo posterior
                    </MenuItem>
                    <MenuItem onClick={() => handleFormatClose("both")}>
                      Ambas caras
                    </MenuItem>
                  </Menu>
                  {props.onCheckAllToPrint && (
                    <Tooltip title="Escoger todos para imprimir">
                      <Checkbox
                        onChange={(event) =>
                          props.onCheckAllToPrint(event.target["checked"])
                        }
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead sx={{ background: "#0c80f4" }}>
            <TableRow>
              {columns.map((column: any, i: any) => (
                <StyledTableCell className="headTable" key={i} align="center">
                  <Typography sx={{ color: "#fff" }}>{column.label}</Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                {columns.map((row: any, i: any) => {
                  if (i === columns.length - 1) {
                    return null;
                  }
                  return (
                    <TableCell key={i}>
                      <Skeleton
                        sx={{ height: 20 }}
                        animation="wave"
                        variant="rectangular"
                        key={i}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ) : rows.length > 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, i: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`table-row-${i}`}
                      onClick={() => {
                        props.onRowClick(row);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {columns.map((column: any, i2: any) => (
                        <>
                          {column.type === "options" && hasOptions ? (
                            <TableCell
                              className="cellTable"
                              key={i2}
                              align={column.align}
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              <ButtonGroup
                                variant="contained"
                                aria-label="button-group-custom-table"
                                style={{
                                  padding: "0px 5px",
                                  color: "#808080",
                                }}
                              >
                                {props.editable &&
                                  (props.rowSelected.id === row.id ? (
                                    <>
                                      <Tooltip title={"Guardar"}>
                                        <IconButton
                                          size="small"
                                          color="inherit"
                                          aria-label="edit"
                                          onClick={() => {
                                            props.onSave();
                                          }}
                                        >
                                          <SaveIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={"Cancelar"}>
                                        <IconButton
                                          size="small"
                                          color="inherit"
                                          aria-label="edit"
                                          onClick={() => {
                                            props.setRowSelected({} as Student);
                                            props.onClear();
                                          }}
                                        >
                                          <ClearIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip title={"Editar"}>
                                        <IconButton
                                          size="small"
                                          color="inherit"
                                          aria-label="edit"
                                          onClick={() => {
                                            props.setRowSelected(row);
                                            props.onClear();
                                          }}
                                        >
                                          <EditIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ))}
                                {props.onCheckbox && (
                                  <Checkbox color="primary" size="small" />
                                )}
                                {props.onEdit && (
                                  <Tooltip title={"Editar"}>
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      aria-label="edit"
                                      onClick={() => {
                                        props.onEdit(row);
                                      }}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {props.onCancel && (
                                  <Tooltip title={"Cancelar/Anular"}>
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      aria-label="cancel"
                                      onClick={() => {
                                        props.onCancel(row);
                                      }}
                                    >
                                      <CancelIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {props.onDelete && (
                                  <Tooltip title={"Eliminar"}>
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      aria-label="delete"
                                      onClick={() => {
                                        props.onDelete(row);
                                      }}
                                    >
                                      <DeleteOutlineIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {props.onUpload && (
                                  <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="upload"
                                    onClick={() => {
                                      props.onUpload(row);
                                    }}
                                  >
                                    <FileUploadIcon fontSize="small" />
                                  </IconButton>
                                )}
                                {props.onCheckIcon &&
                                  props.onClearIcon &&
                                  (row.validation_status === "0" ? (
                                    <>
                                      <Tooltip title="Confirmar pago">
                                        <IconButton
                                          size="small"
                                          color="inherit"
                                          aria-label="upload"
                                          onClick={() => {
                                            props.onCheckIcon(row);
                                          }}
                                        >
                                          <CheckIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Rechazar pago">
                                        <IconButton
                                          size="small"
                                          color="inherit"
                                          aria-label="upload"
                                          onClick={() => {
                                            props.onClearIcon(row);
                                          }}
                                        >
                                          <ClearIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : null)}
                              </ButtonGroup>
                            </TableCell>
                          ) : column.type === "detail" ? (
                            <TableCell
                              className="cellTable"
                              key={i2}
                              align={column.align}
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              {props.onPrintCertificate &&
                                props.onSwitch &&
                                row.print_certificate === "1" && (
                                  <Checkbox
                                    checked={
                                      !!props.selectedRows.find(
                                        ({ id }) => id === row.id
                                      )
                                    }
                                    onChange={(event) =>
                                      props.onPrintCertificateChange(
                                        event.target["checked"],
                                        row
                                      )
                                    }
                                  />
                                )}
                              <ButtonGroup
                                variant="contained"
                                aria-label="button-group-custom-table"
                                style={{
                                  padding: "0px 5px",
                                  color: "#808080",
                                }}
                              >
                                {props.onDetails && (
                                  <Tooltip title={"Ver"}>
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      aria-label="view"
                                      onClick={() => {
                                        props.onDetails(row);
                                      }}
                                    >
                                      <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {props.onPrintCertificate &&
                                  row.print_certificate === "1" && (
                                    <Tooltip title={"Ver"}>
                                      <IconButton
                                        size="small"
                                        color="inherit"
                                        aria-label="view"
                                        onClick={() => {
                                          props.onPrintCertificate(row);
                                        }}
                                      >
                                        <VisibilityIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                {props.onBtnMenu && (
                                  <>
                                    <Tooltip
                                      title={
                                        props?.tooltip_menu
                                          ? props?.tooltip_menu
                                          : ""
                                      }
                                    >
                                      <IconButton
                                        size="small"
                                        color="inherit"
                                        aria-label="view"
                                        onClick={handleOpen}
                                      >
                                        {props?.iconBtnMenu ? (
                                          props?.iconBtnMenu
                                        ) : (
                                          <StackedBarChartIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                    <Menu
                                      id="basic-menu"
                                      open={Boolean(openMenu)}
                                      anchorEl={openMenu}
                                      onClose={() => {
                                        setOpenMenu(null);
                                      }}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <MenuItem>{"Reporte reservas"}</MenuItem>
                                      <MenuItem>{"Reporte deudas"}</MenuItem>
                                    </Menu>
                                  </>
                                )}
                                {props.onPaidIcon && (
                                  <Tooltip title="Ver pagos">
                                    <IconButton
                                      color="inherit"
                                      size="medium"
                                      onClick={() => {
                                        props.onPaidIcon(row);
                                      }}
                                    >
                                      <PaidIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {props.onPT && (
                                  <Tooltip title="Generar Pauta T">
                                    <IconButton
                                      color="inherit"
                                      size="medium"
                                      onClick={() => {
                                        props.onPT(row);
                                      }}
                                    >
                                      <FactCheckIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {props.onHelper && (
                                  <Tooltip
                                    title={
                                      props?.tooltip_helper
                                        ? props?.tooltip_helper
                                        : ""
                                    }
                                  >
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      aria-label="view"
                                      onClick={() => {
                                        props.onHelper(row);
                                      }}
                                    >
                                      {props?.icon_helper ? (
                                        props.icon_helper
                                      ) : (
                                        <HelpIcon fontSize="small" />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ButtonGroup>
                            </TableCell>
                          ) : (
                            <TableCell
                              className="cellTable"
                              key={i2}
                              align={column.align}
                            >
                              {column.format ? (
                                column.format(row)
                              ) : column.type === "input" ? (
                                <TextField
                                  value={row[column.field] || ""}
                                  onChange={(e) =>
                                    props.onChangeInput(
                                      e.target.value,
                                      row,
                                      column.field
                                    )
                                  }
                                  disabled={props.rowSelected.id !== row.id}
                                />
                              ) : column.type === "checkbox" ? (
                                <Switch
                                  checked={row[column.field] === "1"}
                                  onChange={(e) =>
                                    props.onChangeInput(
                                      e.target.checked ? "1" : "0",
                                      row,
                                      column.field
                                    )
                                  }
                                  disabled={props.rowSelected.id !== row.id}
                                />
                              ) : (
                                row[column.field] || ""
                              )}
                            </TableCell>
                          )}
                        </>
                      ))}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={columns ? columns.length : 1}>
                  <Grid
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div>{"Sin data"}</div>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 100]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default CustomTable;
