import { combineReducers } from "redux";
import counterSlice from "./slices/counterSlice";
import authSlice from "./slices/authSlice";
import snackbarSlice from "./slices/snackbarSlice";
import backdropSlice from "./slices/backdropSlice";
import drawerSlice from "./slices/drawerSlice";
import navSectionSlice from "./slices/navSectionSlice";
import headerSlice from "./slices/headerSlice";
import patternRSlice from "./slices/patternRSlice";
import studentSlice from "./slices/studentSlice";
import courseSlice from "./slices/courseSlice";
import cycleSlice from "./slices/cycleSlice";
import programSlice from "./slices/programSlice";

const reducers = combineReducers({
  counterSlice: counterSlice,
  authSlice: authSlice,
  snackbarSlice: snackbarSlice,
  backdropSlice: backdropSlice,
  drawerSlice: drawerSlice,
  navSectionSlice: navSectionSlice,
  headerSlice: headerSlice,
  patternRSlice: patternRSlice,
  studentSlice: studentSlice,
  courseSlice: courseSlice,
  programSlice: programSlice,
  cycleSlice: cycleSlice,
});

export default reducers;
