import { FormLabel, Grid } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { useState } from "react";
import { useCycleStore } from "../../../hooks/useCycleStore";

export const UploadExcelModal: React.FC<any> = (props): JSX.Element | any => {
  const { openModal, setOpenModal, selectedCycle, getStudentByCycle } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const { uploadTemplateStudentByCycle } = useCycleStore();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("excel", selectedFile);
      const response = await uploadTemplateStudentByCycle(
        selectedCycle.id,
        formData
      );
      if (response) {
        setOpenModal(false);
        getStudentByCycle(selectedCycle.id);
      }
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
        style={{ zIndex: 1000 }}
      >
        <ModalHeader
          text={"Cargar Data por Excel"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditeditCycle}
        ></ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <FormLabel sx={{ mr: 5, fontSize: 16 }}>Excel:</FormLabel>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                  style={{ marginBottom: "16px" }}
                />
                {/* <Button variant="contained" color="primary" onClick={handleSubmit}>
                                        Subir Excel
                                    </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter
          confirmText={"Confirmar"}
          onConfirm={handleSubmit}
          cancelText={"Cancelar"}
          onCancel={() => {
            setSelectedFile(null);
            setOpenModal(false);
          }}
          className="modal-confirm-footer"
          // loadingConfirmText={status2}
        />
      </Modal>
    </>
  );
};
