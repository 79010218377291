import { DialogActions, Button, CircularProgress } from '@mui/material';
import '../../../assets/styles/modals.css';

export const ModalFooter = (props:any) => {

  const { 
    confirmText, onConfirm, cancelText, onCancel, cancelColor, confirmColor, className, backgroundColor, buttonType, secondaryText, onSecondaryConfirm, clearState, loadingConfirmText, disabledConfirmText
  } = props;

    return (
      <DialogActions className={className || "modal-footer"} style={{position: 'sticky'}}>
        {
          cancelText && (
            <Button onClick={()=>{onCancel && onCancel(); clearState && clearState()}} size="small" color={cancelColor || "secondary"} variant="contained" className={backgroundColor || ''}
            sx={{background:'#808080',color:'#fff', '&:hover':{bgcolor:'#a79f9f'}}}>
              {cancelText}
            </Button>
          )
        }
        {
          confirmText && (
            <Button onClick={onConfirm} disabled={loadingConfirmText || disabledConfirmText} type={buttonType} size="small" color={confirmColor || "primary"} variant="contained"
            sx={{background:'#212D39',color:'#fff', '&:hover':{ bgcolor:'#555e66'}}}>
              {confirmText}
              {
                loadingConfirmText && (
                  <CircularProgress color="inherit" size={12} sx={{ color: '#fff', marginLeft: "10px" }} />
                )
              }
            </Button>
          )
        }
        {
          secondaryText && (
            <Button onClick={onSecondaryConfirm} type={buttonType} size="small" color={"error" || confirmColor} variant="contained">
              {secondaryText}
            </Button>
          )
        }
      </DialogActions>
    )
}