export enum LocalStorageKey {
    USER_DATA      = 'userData',
    OPTIONS_MEDICAL_CENTER = 'optionsMedicalCenter',
    MEDICAL_CENTER = 'medicalCenter',
    RUTA = 'keyRuta',
    ARRAY_MY_MENU  = 'arrayMyMenu',
    OBJECT_MY_MENU = 'objectMyMenu',
    EMPRESA        = 'userEmpresa',
    USER_SOFTNET     = 'userDataSoftnet',
    TOKEN_SOFTNET  =  'token',
    TOOGLE_MENU    = 'userToogleMenu',
    SOFTNET        = 'tokenSoftnet',
    BYPASS         = 'tokenBypass',
    NAVEGACION_SUB_MODULO   = 'userSubNavigators',
    TOKEN_KYTE = 'tokenkyte',
    DATA_CAMPAING_LEADS = 'dataCampaingLeads',
    DOCUMENTS = 'dataDocuments',
    PROFESSIONAL_FILTER = 'professionalFilter',
    ID_PROFESSIONAL = 'idProfessional',
    SERVICE_SELECTED = 'serviceSelected',
    INITIAL_DATE_CLIENT_CALENDAR = 'initialDateClientCalendar'
  }