import { createSlice } from "@reduxjs/toolkit";

const initialState: any =
{
  showBackdrop: false,
}

const backdropSlice = createSlice({
    name: 'backdropSlice',
    initialState,
    reducers:{
        onShowBackdrop (state) {
            state.showBackdrop  = true
        },
        onHideBackdrop ( state ) {
            state.showBackdrop  = false
        },
    }
});

export const { onShowBackdrop, onHideBackdrop } = backdropSlice.actions;
export const selectAuth = (state:any) => state.snackbarSlice;
export default backdropSlice.reducer;