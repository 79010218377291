import { CardProps, Card, styled } from "@mui/material";

const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
  borderRadius: 15,
  padding: 20,
  textAlign: "center",

  [theme.breakpoints.up("sm")]: {
    marginTop: 40,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 30,
  },
}));

const StudentCard = ({ data }: any) => {
  return (
    <div style={{ width: "100%" }}>
      <CustomCard>
        <div style={{ color: "#5f5f5f", fontSize: 15 }}>Alumno:</div>

        <img
          className="avatar"
          src="./icons/avatar.png"
          width={140}
          alt="avatar"
          style={{
            paddingTop: 15,
            paddingBottom: 15,
          }}
        />

        <div
          style={{
            fontWeight: "600",
            textTransform: "uppercase",
            color: "#5F616E",
            fontSize: 20,
          }}
        >
          {data.name_complet_student}
        </div>
        <div
          style={{
            color: "#5F616E",
            fontSize: 20,
            paddingTop: 10,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            alt="dni"
            src="./icons/dni.png"
            width={30}
            style={{ marginRight: 10 }}
          />
          DNI: <strong>{data.dni_student}</strong>
        </div>
      </CustomCard>
    </div>
  );
};

export default StudentCard;
