import PropTypes from 'prop-types'
import { memo } from 'react'
// @mui
import { Box } from '@mui/material'
//
import { StyledRootScrollbar, StyledScrollbar } from './Styles'

// ----------------------------------------------------------------------

// Scrollbar.propTypes = {
//   sx: PropTypes.object,
//   children: PropTypes.node,
// }

const ScrollBar: React.FC<any> = ({ children, sx, ...other }): JSX.Element | any => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    )
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  )
}

export default memo(ScrollBar)
