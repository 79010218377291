import { api } from "./configs/axiosConfigs";

export const CycleAPI = {
  //ciclos
  getCycle: async () => {
    const response = await api
      .get("/cycle")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getTeacher: async () => {
    const response = await api
      .get("/teacher")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getProgram: async () => {
    const response = await api
      .get("/program")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createCycle: async (data: any) => {
    const response = await api
      .post("/cycle", { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editCycle: async (id: number, data: any) => {
    const response = await api
      .patch(`/cycle/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteCycle: async (id: number) => {
    const response = await api
      .delete(`/cycle/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },

  //detalle ciclo
  getStudentByCycle: async (idcycle) => {
    const response = await api
      .get(`/detailCycleStudent/${idcycle}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getStudentByCycleSearch: async (idcycle, term) => {
    const response = await api
      .get(`/detailCycleStudent/${idcycle}?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getStudentNotInCycle: async (idcycle) => {
    const response = await api
      .get(`/detailCycleStudent/notStudent/${idcycle}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createRelationStudentCycle: async (id: number, data: any) => {
    const response = await api
      .get(`/detailCycleStudent/relation/${id}?ids_student=${data}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editStudentCycle: async (id: number, data: any) => {
    const response = await api
      .patch(`/detailCycleStudent/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteStudentCycle: async (idcycle: number, idstudents: any) => {
    const response = await api
      .delete(
        `/detailCycleStudent/${idcycle}?ids_student=${idstudents}&status=2`
      )
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },

  getTemplateStudentByCycle: async (idcycle) => {
    const response = await api
      .post(`/detailCycleStudent/generatePlantilla/${idcycle}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  uploadTemplateStudentByCycle: async (idcycle, data) => {
    const response = await api
      .post(`/detailCycleStudent/uploadPlantilla/${idcycle}`, data)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },
};
