import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type DrawerSliceType = {
  drawerOpen: boolean
}

const initialState: DrawerSliceType =
{
  drawerOpen: false
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    onOpenDrawer (state, { payload }: { payload: boolean}) {
      state.drawerOpen    = payload
    }
  }
})

export const selectDrawerState = (state: RootState) => state.drawerSlice;
export default drawerSlice.reducer

export const {
  onOpenDrawer
} = drawerSlice.actions
