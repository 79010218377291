import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BoxLogo = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    padding: 35,
    textAlign: "center",
    ".logo": {
      width: "80%",
    },
    ".avatar": {
      display: "none",
    },
    ".slogan": {
      display: "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 300,
    marginLeft: 80,
    marginRight: 80,
    height: "100vh",
  },
}));
