import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const PrimaryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#0C46FF",
  color: "#FFF",
  borderRadius: 25,
  height: 50,
  fontSize: 20,
  "&:hover": {
    backgroundColor: "#0C46FF",
  },
}));
