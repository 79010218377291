import { Autocomplete, FormLabel, Grid, TextField } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useCycleStore } from "../../../hooks/useCycleStore";
import { Cycle, Teacher } from "../../../types/slices/cycleType";
import { useCourseStore } from "../../../hooks/useCourseStore";
import { Course } from "../../../types/slices/coursesType";
import { Program } from "../../../types/slices/programsType";

export const CreateEditCycleModal: React.FC<any> = (): JSX.Element | any => {
  const {
    editCycle,
    openModal,
    setOpenModal,
    isEdit,
    createCycleStore,
    editCycleStore,
    getCycle,
    getTeacher,
    getProgram,
    setSelectedTeacher,
    setSelectedProgram,
    selectedTeacher,
    selectedProgram,
    teachers,
    programs,
  } = useCycleStore();
  const { courses, getCourses, selectedCourse, setSelectedCourse } =
    useCourseStore();
  const [data, setData] = useState<Cycle>({
    name: "",
    teacher: "",
    date_start: "",
    date_end: "",
    course: "",
    idcourse: "",
    program: "",
    idprogram: "",
  });

  const validateForm = (values) => {
    console.log(values);
    let errors: any = {};
    if (!values.name) errors.name = "Nombre es requerido";
    if (!values.teacher && !values.idteacher) {
      errors.teacher = "Profesor es requerido";
      errors.idteacher = "Profesor es requerido";
    }
    if (!values.idcourse) errors.idcourse = "Curso es requerido";
    if (!values.idprogram) errors.idprogram = "Programa es requerido";
    if (!values.date_start) errors.date_start = "Fecha de Inicio es requerido";
    if (!values.date_end) errors.date_end = "Fecha Fin es requerido";
    if (new Date(values.date_end) < new Date(values.date_start))
      errors.date_end = "Fecha Fin no puede ser menor a Fecha de Inicio";
    return errors;
  };

  const onSubmit = async (values) => {
    let params: any = {
      name: values.name,
      idcourse: selectedCourse.id,
      idprogram: selectedProgram.id,
      date_start: values.date_start,
      date_end: values.date_end,
    };

    if (selectedTeacher.id) {
      params = { ...params, idteacher: selectedTeacher.id };
    } else {
      params = { ...params, teacher: values.teacher };
    }
    const method = isEdit
      ? editCycleStore(editCycle?.id || 0, params)
      : createCycleStore(params);
    const response = await method;
    if (response === true) {
      getCycle();
      setOpenModal(false);
    }
  };

  useEffect(() => {
    getCourses();
    getProgram();
    getTeacher();
    if (isEdit) {
      setData(editCycle);
      setSelectedCourse(editCycle?.courses || ({} as Course));
      setSelectedTeacher(editCycle?.teachers || ({} as Teacher));
      setSelectedProgram(editCycle?.programs || ({} as Program));
    } else {
      setData({
        name: "",
        idcourse: 0,
        idteacher: 0,
        idprogram: 0,
        date_start: "",
        date_end: "",
      });
      setSelectedCourse({} as Course);
      setSelectedTeacher({} as Teacher);
      setSelectedProgram({} as Program);
    }
  }, [isEdit]);

  const onChangeTeacher = (newValue: Teacher | null, setFieldValue: any) => {
    if (newValue) {
      setFieldValue("idteacher", newValue.id);
      setSelectedTeacher(newValue);
    } else {
      setFieldValue("idteacher", "");
      setSelectedTeacher({} as Teacher);
    }
  };

  const onChangeProgram = (newValue: Program | null, setFieldValue: any) => {
    if (newValue) {
      setFieldValue("idprogram", newValue.id);
      setSelectedProgram(newValue);
    } else {
      setFieldValue("idprogram", "");
      setSelectedProgram({} as Program);
    }
  };

  const onChangeCourse = (newValue: Course | null, setFieldValue: any) => {
    if (newValue) {
      setFieldValue("idcourse", newValue.id);
      setSelectedCourse(newValue);
    } else {
      setFieldValue("idcourse", "");
      setSelectedCourse({} as Course);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        disableEscapeKeyDown
        disableBackdropClick
        size="sm"
      >
        <ModalHeader
          text={isEdit ? "Editar Ciclo" : "Crear Ciclo"}
          className="positionElements"
          onCancel={() => setOpenModal(false)}
          // clearState={clearEditeditCycle}
        ></ModalHeader>

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) =>
            validateForm({ ...values, selectedCourse: selectedCourse })
          }
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                          <FormLabel>Programa:</FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-course"
                            value={selectedProgram}
                            options={programs || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={errors.idprogram ? true : false}
                              />
                            )}
                            onChange={(e, newValue) => {
                              onChangeProgram(newValue, setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Nombre Ciclo:</FormLabel>
                          <TextField
                            id="name"
                            type="text"
                            name="name"
                            fullWidth
                            size="small"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Profesor Existente:</FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-course"
                            value={selectedTeacher}
                            options={teachers || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={errors.idteacher ? true : false}
                              />
                            )}
                            onChange={(e, newValue) => {
                              onChangeTeacher(newValue, setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Profesor Nuevo:</FormLabel>
                          <TextField
                            id="teacher"
                            type="text"
                            name="teacher"
                            fullWidth
                            size="small"
                            value={values.teacher}
                            onChange={handleChange}
                            error={
                              errors.teacher && touched.teacher ? true : false
                            }
                            helperText={
                              errors.teacher && touched.teacher
                                ? errors.teacher
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Cursos:</FormLabel>
                          <Autocomplete
                            disablePortal
                            id="combo-box-course"
                            value={selectedCourse}
                            options={courses || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option?.name === value?.name
                            }
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={errors.idcourse ? true : false}
                              />
                            )}
                            onChange={(e, newValue) => {
                              onChangeCourse(newValue, setFieldValue);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Fecha de Inicio:</FormLabel>
                          <TextField
                            id="date_start"
                            type="date"
                            name="date_start"
                            fullWidth
                            size="small"
                            value={values.date_start}
                            onChange={handleChange}
                            error={
                              errors.date_start && touched.date_start
                                ? true
                                : false
                            }
                            helperText={
                              errors.date_start && touched.date_start
                                ? errors.date_start
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Fecha Fin:</FormLabel>
                          <TextField
                            id="date_start"
                            type="date"
                            name="date_end"
                            fullWidth
                            size="small"
                            value={values.date_end}
                            onChange={handleChange}
                            error={
                              errors.date_end && touched.date_end ? true : false
                            }
                            helperText={
                              errors.date_end && touched.date_end
                                ? errors.date_end
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </ModalBody>
                <ModalFooter
                  confirmText={isEdit ? "Actualizar" : "Registrar"}
                  onConfirm={handleSubmit}
                  cancelText={"Cancelar"}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  className="modal-confirm-footer"
                  // loadingConfirmText={status2}
                />
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
