import { createSlice } from '@reduxjs/toolkit'
import { Header } from '../../types/slices/headerType'
import { RootState } from "../store";

const initialState: Header =
{
  search: ""
}

const headerSlice = createSlice({
  name: 'headerSlice',
  initialState,
  reducers: {
    onSetSearch (state, { payload }: { payload: string}) {
      state.search = payload
    }
  }
})

export const selectHeaderState = (state: RootState) => state.headerSlice

export default headerSlice.reducer
export const {
    onSetSearch
} = headerSlice.actions
