
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAuthStore } from '../../../hooks'
import { useDrawerStore } from '../../../hooks/useDrawerStore'
import { Role } from '../../../types/roles/roleTypes'
import AccountPopover from './AccountPopover'
import { RoutesMap } from '../../../types'
import { useNavigate } from 'react-router-dom'
import { CustomSearchByTable } from '../CustomSearchByTable/CustomSearchByTable'
import { useNavSectionStore } from '../../../hooks/useNavSectionStore'

// ----------------------------------------------------------------------

export const HeaderView = (): JSX.Element => {

    const navigate = useNavigate()

    //Hooks
    const { user } = useAuthStore()
    const { drawerOpen, openDrawer } = useDrawerStore()
    const { navOpen, openNavSection } = useNavSectionStore()

    const MARGIN_RIGHT      = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN || user.userType == Role.MAINTENANCE) ? 16 : 0
    const BORDER_RADIUS     = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN || user.userType == Role.MAINTENANCE) ? 10 : 0
    const MARGIN_TOP        = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN || user.userType == Role.MAINTENANCE) ? 5 : 0
    // const BACKGROUND_COLOR  = user.userType == Role.OWNER || user.userType == Role.FAMILY ? '#212D39' : '#C3DEFF'
    // const ICON_COLOR_HEADER = user.userType == Role.OWNER || user.userType == Role.FAMILY ? '#FFFFFF' : '#7a7a7a'
    const BACKGROUND_COLOR  = '#fff'
    const ICON_COLOR_HEADER = '#7a7a7a'
    const HEADER_MOBILE     = 64
    const HEADER_DESKTOP    = 50

    const NAV_WIDTH = navOpen ? 312 : 30
    // if (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN || user.userType == Role.MAINTENANCE) {
    //     NAV_WIDTH = navOpen ? 312 : 30
    // } else {
    //     NAV_WIDTH = 0
    // }

    const StyledRoot = styled(AppBar)(({ theme }) => ({
        backgroundColor: BACKGROUND_COLOR,
        boxShadow: 'none',
        [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH}px)`,
        maxHeight: '75px',
        minHeight: '75px',
        marginRight: MARGIN_RIGHT,
        borderRadius: BORDER_RADIUS,
        marginTop: MARGIN_TOP,
        overflow: 'hidden',
        },
    }))

    const StyledToolbar = styled(Toolbar)(({ theme }) => ({
        alignItems:'center',
        minHeight: HEADER_MOBILE,
        [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 3),
        },
    }))

    const onChangeLogo = () => {
        switch (user.userType) {
            case Role.SUPER_ADMIN:
              navigate({ pathname: RoutesMap.STUDENTS}, { replace: true })
              break
            default:
              break
        }
    }

    return (
        <>
        <StyledRoot >
            <StyledToolbar>
                {/* {
                    user && user.userType == Role.SUPER_ADMIN && (
                        <IconButton
                            onClick={() => openDrawer(!drawerOpen)}
                            sx={{
                                mr: 1,
                                color: 'text.primary',
                                display: { lg: 'none', xl: 'none' },
                            }}
                        >
                            <MenuIcon sx={{color: ICON_COLOR_HEADER}}/>
                        </IconButton>
                    )
                } */}
                {/* {
                    user && user.userType == Role.SUPER_ADMIN && (
                        <Grid mt={2}>
                            <IconButton
                                onClick={() => openNavSection(!navOpen)}
                                sx={{
                                    mr: 1,
                                    color: 'text.primary',
                                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                                }}
                            >
                                <MenuIcon sx={{color: ICON_COLOR_HEADER}}/>
                            </IconButton>
                        </Grid>
                        
                    )
                } */}
                <IconButton
                    onClick={() => openDrawer(!drawerOpen)}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        display: { lg: 'none', xl: 'none' },
                    }}
                >
                    <MenuIcon sx={{color: ICON_COLOR_HEADER}}/>
                </IconButton>
                <Grid mt={2}>
                    <IconButton
                        onClick={() => openNavSection(!navOpen)}
                        sx={{
                            mr: 1,
                            color: 'text.primary',
                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                        }}
                    >
                        <MenuIcon sx={{color: ICON_COLOR_HEADER}}/>
                    </IconButton>
                </Grid>
                {/* {
                    ((user && user.userType != Role.ADMIN) && (user && user.userType != Role.SUPER_ADMIN) ) && (
                        <Grid sx={{height:'40px', width:'125px', cursor:'pointer', marginBottom:'10px'}} onClick={onChangeLogo} >
                            <img style={{ width: '110px'}} src={logoheader}/>
                        </Grid>
                    )
                } */}

                {
                    // user && (user.userType == Role.OWNER)&& (
                    //     <Grid item xs={2} md={6} ml={2} mt={3}>
                    //         <Grid display="flex" justifyContent="flex-end" alignItems="center" container spacing={1} direction="row">
                    //             <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RESERVATION })}>
                    //                 {/* <InsertInvitationIcon sx={{fontSize:'30px', color:'white'}} /> */}
                    //                 <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{ml:1 , fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                    //                     {'Mis reservas'}
                    //                 </Typography>
                    //             </IconButton>
                    //             <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RENDITION })}>
                    //                 {/* <ReceiptLongIcon sx={{fontSize:'30px', color:'white'}} /> */}
                    //                 <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{ml:1 , fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                    //                     {'Rendiciones'}
                    //                 </Typography>
                    //             </IconButton>
                    //             <IconButton onClick={() =>  navigate({ pathname: RoutesMap.AFFILIATED_FAMILY })}>
                    //                 {/* <FamilyRestroomIcon sx={{fontSize:'30px', color:'white'}} /> */}
                    //                 <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{ml:1 , fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                    //                     {'Afiliados'}
                    //                 </Typography>
                    //             </IconButton>
                    //         </Grid>
                    // </Grid>
                    // )
                }

                {
                    // user && (user.userType === Role.FAMILY)&& (
                    //     <Grid item xs={2} md={8} ml={2} mt={3}>
                    //         <Grid display="flex" justifyContent="flex-end" alignItems="center" container spacing={1} direction="row">
                    //             <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RESERVATION })}>
                    //                 <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{ml:1 , fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                    //                     {'Mis reservas'}
                    //                 </Typography>
                    //             </IconButton>
                    //             <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RENDITION })}>
                    //                 <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{ml:1 , fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                    //                     {'Rendiciones'}
                    //                 </Typography>
                    //             </IconButton>
                    //         </Grid>
                    //     </Grid>
                    // )
                }

                {<Box sx={{ flexGrow: 1 }} />}

                {/* {
                    ((user && user.userType != Role.ADMIN) && (user && user.userType != Role.SUPER_ADMIN) ) && (
                        <CustomSearch/>
                    )
                } */}

                {
                    // ((user && user.userType == Role.ADMIN) || (user && user.userType == Role.SUPER_ADMIN) ) && (
                    //     <CustomSearchByTable/>
                    // )
                    // <CustomSearchByTable/>
                }

                {<Box sx={{ flexGrow: 1 }} />}

                <Grid item xs={2} md={6} mt={3}>
                    <Grid display="flex" justifyContent="flex-end" alignItems="center" container spacing={1} direction="row">
                        {/* {
                            ((user && user.userType != Role.ADMIN) && (user && user.userType != Role.SUPER_ADMIN) ) && (
                                <>
                                <IconButton>
                                    <ChangeDepartament colorIcon={ICON_COLOR_HEADER} />
                                </IconButton>
                                <span className='divider'></span>
                                </>
                            )
                        } */}
                        
                        {/* <IconButton>
                            <NotificationsIcon sx={{fontSize:'22px', color:ICON_COLOR_HEADER}} />
                        </IconButton> */}
                        <IconButton>
                            <AccountPopover/>
                        </IconButton>
                    </Grid>
                </Grid>
            </StyledToolbar>
        </StyledRoot>
        </>
    )
}
