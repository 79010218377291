import { api } from "./configs/axiosConfigs";

export const CertificateAPI = {
  getCertificate: async () => {
    const response = await api
      .get(`/certificate?status=1`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getCertificateSerch: async (term) => {
    const response = await api
      .get(`/certificate?status=1&term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getCertificateByDNI: async (dni) => {
    const response = await api
      .get(`/certificate/${dni}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDF: async (id) => {
    const response = await api
      .get(`/certificate/pdf/${id}`, { responseType: "blob" })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDFTemplate: async (id) => {
    const response = await api
      .get(`/certificate/pdf-template/${id}`, { responseType: "blob" })
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDFMultipleTemplate: async (
    idcycle,
    ids,
    type: string,
    side = "both"
  ) => {
    const response = await api
      .post(
        `/certificate/pdf-multiple/${idcycle}`,
        {
          ids,
          type,
          side,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  downloadPDFByUrl: async (id) => {
    const response = await api
      .get(`/certificate/pdf-url/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },
};
