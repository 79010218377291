import { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAuthStore } from "../../hooks";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { RoutesMap } from "../../types";
import logo from "../../assets/image/logo_cfe.png";
import { Role } from "../../types/roles/roleTypes";
import "./Login.css";
import { Formik } from "formik";

const themeAvenir = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 15,
  },
});

type Values = {
  dni: string;
  password: string;
  perfil: number | undefined | null;
};

export const LoginView: React.FC<any> = (props: any): JSX.Element | any => {
  const { login } = useAuthStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [data, setData] = useState<Values>({
    dni: "",
    password: "",
    perfil: 1,
  });

  const validateForm = (values) => {
    let errors: any = {};
    let regdni = /^[0-9]{8}$/;
    if (!values.dni) errors.dni = "dni requerido";
    if (values.dni && !regdni.test(values.dni)) errors.dni = "dni no válido";
    if (!values.password) errors.password = "contraseña requerida";
    return errors;
  };

  const handleClickShowPassword = () => {
    if (showPassword) setShowPassword(false);
    if (!showPassword) setShowPassword(true);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    const { dni, password, perfil } = values;
    onSendLogin(perfil, password, dni);
  };

  const onSendLogin = async (perfil, password, dni) => {
    setLoading(true);
    try {
      const success: any = await login({ iduser_type: perfil, password, dni });
      if (!success?.status) {
        setLoading(false);
        return;
      }

      let role = success?.detail?.userType;

      switch (role) {
        case Role.ADMIN:
          navigate({ pathname: RoutesMap.CYCLE }, { replace: true });
          break;
        default:
          break;
      }
      setLoading(false);
    } catch (error) {
      setData((prev) => ({
        ...prev,
        textError: "Lo sentimos, ocurrió un error inesperado.",
      }));
      setLoading(false);
    }
  };

  const formulario = (
    <Paper
      elevation={5}
      sx={{
        borderRadius: "16px",
        height: { xs: "33vh", sm: "100vh", md: "35vh", lg: "35vh" },
        width: { sm: "60vw", md: "30vw" },
      }}
    >
      <Grid
        xs={12}
        sx={{
          backgroundColor: "#003c84",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          height: "10vh",
        }}
      >
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Stack direction="row" justifyContent="start">
            <Typography
              variant="h5"
              color="#fff"
              sx={{
                fontWeight: 700,
                margin: "15px auto 0px",
                fontSize: "3.2vh",
              }}
            >
              INGRESA TU USUARIO
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Stack direction="row" justifyContent="start">
            <Typography
              variant="body2"
              color="#fff"
              sx={{ fontWeight: 300, paddingBottom: 3, fontSize: "1.8vh" }}
            >
              Completa el formulario con tus datos
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        sx={{
          margin: {
            xs: "10px",
            sm: "10 px 20px",
            md: "10px 20px",
            lg: "2vh 2vh",
            xl: "2vh 2vh",
          },
        }}
      >
        {/* Title */}

        <Formik
          initialValues={data}
          enableReinitialize
          validate={(values) => validateForm(values)}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <ThemeProvider theme={themeAvenir}>
                  <Grid container spacing={2} sx={{ height: "16vh" }}>
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="dni"
                          name="dni"
                          fullWidth
                          variant="outlined"
                          value={values.dni}
                          onChange={handleChange}
                          placeholder="Ingrese su dni"
                          size="small"
                          type="text"
                          sx={{ color: "#003c84", borderColor: "#003c84" }}
                          InputProps={{
                            inputProps: {
                              maxLength: 10,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon sx={{ color: "#003c84" }} />
                              </InputAdornment>
                            ),
                          }}
                          error={errors.dni && touched.dni ? true : false}
                          helperText={
                            errors.dni && touched.dni ? errors.dni : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="password"
                          name="password"
                          fullWidth
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange}
                          size="small"
                          placeholder="Ingresa su clave"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon sx={{ color: "#003c84" }} />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  sx={{ color: "#003c84" }}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={
                            errors.password && touched.password ? true : false
                          }
                          helperText={
                            errors.password && touched.password
                              ? errors.password
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="center">
                        <Button
                          type="submit"
                          disabled={loading}
                          fullWidth
                          size="small"
                          variant="contained"
                          sx={{
                            width: "150px",
                            height: "3.6vh",
                            borderRadius: "100px",
                            fontFamily: "Poppins, sans-serif !important",
                            fontWeight: 700,
                            background: "#024cb7",
                            color: "#fff",
                            "&:hover": { bgcolor: "#003c84" },
                          }}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={28} />
                          ) : (
                            "Continuar"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </ThemeProvider>
              </form>
            );
          }}
        </Formik>
      </Grid>
    </Paper>
  );

  return (
    <Box className="login-main-container">
      <Grid container>
        <Grid item xs={12} className="login-container">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              // bgcolor:'red',
              paddingRight: "10vh",
              height: "100vh",
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              }, // Cambio importante: usa flex para centrar
              alignItems: "center", // Centra verticalmente
              justifyContent: "right", // Centra horizontalmente
              textAlign: { md: "end", xs: "start" },
            }}
          >
            <img style={{ height: "15vh" }} src={logo} alt="logo" />
          </Grid>

          <Grid
            item
            xs={11}
            sm={10}
            md={6}
            lg={6}
            sx={{
              // bgcolor:'black' ,
              margin: "0px auto",
              marginX: { sm: "10vw", md: "0vw" },
              height: "100vh",
              width: { sm: "80vw", md: "50vw" },
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              paddingLeft: { sm: "10vh", lg: "10vh" },
            }}
          >
            {formulario}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
