import { api } from './configs/axiosConfigs'

export const ProfileAPI = {
  get: async (token: string) => {

    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await api.post(
        '/auth/me'
    ).then(response => response.data.data)
        .catch((error) => {
            console.error(error)
        })
    return response
  }
}