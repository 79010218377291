import { api } from "./configs/axiosConfigs";

export const ProgramAPI = {
  getPrograms: async () => {
    const response = await api
      .get("/program")
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  getProgramsSearch: async (term) => {
    const response = await api
      .get(`/program?term=${term}`)
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  createProgram: async (data: any) => {
    const response = await api
      .post("/program", { ...data, status: "1" })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  editProgram: async (id: number, data: any) => {
    const response = await api
      .patch(`/program/${id}`, { ...data })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        return error.response.data;
      });
    return response;
  },

  deleteProgram: async (id: number) => {
    const response = await api
      .delete(`/program/${id}?status=2`)
      .then((response) => response.data)
      .catch((error) => {
        return error.response.data;
      });
    return response;
  },
};
