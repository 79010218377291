import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const StandardTextField = styled(TextField)<TextFieldProps>(() => ({
  backgroundColor: `#F2F3F7`,
  color: "#FFF",
  borderRadius: 15,
  fontSize: 17,
  padding: "10px 20px",
  marginBottom: 15,
  label: {
    paddingLeft: 10,
  },
  "& div:nth-of-type(1)": {
    marginTop: 0,
    backgroundColor: `#F2F3F7`,
  },
  "& div:nth-of-type(1):before": {
    borderBottom: "0 !important",
  },
  "& div:nth-of-type(1):after": {
    borderBottom: "0 !important",
  },
}));
