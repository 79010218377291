import { useDispatch, useSelector } from "react-redux";
import {
    onFetchCourse,
    onFetchModules,
    onFetchTheme,
    changeStatusCourses,
    changeStatusModules,
    changeStatusThemes,
    onSetEditCourse,
    onSetSelectedCourse,
    onSetEditModule,
    onSetSelectedModule,
    onSetEditTheme,
    onSetSelectedTheme,
    onSetOpenModal,
    onChangeIsEdit
} from "../redux/slices/courseSlice";

import { ApiStatus } from "../types/api/status";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { selectCourseState } from "../redux/slices/courseSlice";
import { StudentAPI } from "../apis/StudentAPI";
import { CourseAPI } from "../apis/CourseAPI";
import { Course, Module, Theme } from "../types/slices/coursesType";

export const useCourseStore = () => {
    const dispatch = useDispatch();
    const { courses, modules, themes, statusCourses, editCourse, statusModules, editModule, editTheme, statusThemes, selectedCourse, selectedModule, selectedTheme, openModal, isEdit } =
        useSelector(selectCourseState);

    const getCourses = async () => {
        try {
            dispatch(changeStatusCourses(ApiStatus.FETCHING));
            const response = await CourseAPI.getCourses();
            if (!response?.status) {
                dispatch(changeStatusCourses(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchCourse(detail));
            dispatch(changeStatusCourses(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusCourses(ApiStatus.FETCHED));
        }
    };

    const getCoursesSearch = async (term) => {
        try {
            dispatch(changeStatusCourses(ApiStatus.FETCHING));
            const response = await CourseAPI.getCoursesSearch(term);
            if (!response?.status) {
                dispatch(changeStatusCourses(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchCourse(detail));
            dispatch(changeStatusCourses(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusCourses(ApiStatus.FETCHED));
        }
    };

    const createCourseStore = async (data: any) => {
        try {
            dispatch(changeStatusCourses(ApiStatus.FETCHING));
            const response = await CourseAPI.createCourse(data);
            if (!response?.status) {
                dispatch(changeStatusCourses(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatusCourses(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusCourses(ApiStatus.FETCHED));
        }
    };

    const editCourseStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatusCourses(ApiStatus.FETCHING));
            const response = await CourseAPI.editCourse(id, data);
            if (!response?.status) {
                dispatch(changeStatusCourses(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatusCourses(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusCourses(ApiStatus.FETCHED));
        }
    };

    const deleteCourse = async (id: number) => {
        try {
            dispatch(changeStatusCourses(ApiStatus.FETCHING));
            const response = await CourseAPI.deleteCourse(id);
            if (!response?.status) {
                dispatch(changeStatusCourses(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatusCourses(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusCourses(ApiStatus.FETCHED));
        }
    };

    const setSelectedCourse = (course: Course) => {
        try {
            dispatch(onSetSelectedCourse(course));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditCourse = (course: Course) => {
        try {
            dispatch(onSetEditCourse(course));
        } catch (error) {
            console.log(error);
        }
    };


    //MODULOS


    const getModulesByCourse = async (idCourse) => {
        try {
            dispatch(changeStatusModules(ApiStatus.FETCHING));
            const response = await CourseAPI.getModulesByCourse(idCourse);
            if (!response?.status) {
                dispatch(changeStatusModules(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchModules(detail));
            dispatch(changeStatusModules(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusModules(ApiStatus.FETCHED));
        }
    };

    const createModuleStore = async (data: any) => {
        try {
            dispatch(changeStatusModules(ApiStatus.FETCHING));
            const response = await CourseAPI.createModule(data);
            if (!response?.status) {
                dispatch(changeStatusModules(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatusModules(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusModules(ApiStatus.FETCHED));
        }
    };

    const editModuleStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatusModules(ApiStatus.FETCHING));
            const response = await CourseAPI.editModule(id, data);
            if (!response?.status) {
                dispatch(changeStatusModules(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatusModules(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusModules(ApiStatus.FETCHED));
        }
    };

    const deleteModule = async (id: number) => {
        try {
            dispatch(changeStatusModules(ApiStatus.FETCHING));
            const response = await CourseAPI.deleteModules(id);
            if (!response?.status) {
                dispatch(changeStatusModules(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatusModules(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusModules(ApiStatus.FETCHED));
        }
    };

    const setSelectedModule = (module: Module) => {
        try {
            dispatch(onSetSelectedModule(module));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditModule = (module: Module) => {
        try {
            dispatch(onSetEditModule(module));
        } catch (error) {
            console.log(error);
        }
    };

    //TEMAS

    const getThemeByModule = async (idModule) => {
        try {
            dispatch(changeStatusThemes(ApiStatus.FETCHING));
            const response = await CourseAPI.getThemeByModule(idModule);
            if (!response?.status) {
                dispatch(changeStatusThemes(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            const { detail } = response.data;
            dispatch(onFetchTheme(detail));
            dispatch(changeStatusThemes(ApiStatus.FETCHED));
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusThemes(ApiStatus.FETCHED));
        }
    };

    const createThemeStore = async (data: any) => {
        try {
            dispatch(changeStatusThemes(ApiStatus.FETCHING));
            const response = await CourseAPI.createTheme(data);
            if (!response?.status) {
                dispatch(changeStatusThemes(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatusThemes(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusThemes(ApiStatus.FETCHED));
        }
    };

    const editThemeStore = async (id: number, data: any) => {
        try {
            dispatch(changeStatusThemes(ApiStatus.FETCHING));
            const response = await CourseAPI.editTheme(id, data);
            if (!response?.status) {
                dispatch(changeStatusThemes(ApiStatus.FETCHED));
                return CustomSnackbar("error", response.data.message || "");
            }
            dispatch(changeStatusThemes(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusThemes(ApiStatus.FETCHED));
        }
    };

    const deleteTheme = async (id: number) => {
        try {
            dispatch(changeStatusThemes(ApiStatus.FETCHING));
            const response = await CourseAPI.deleteTheme(id);
            if (!response?.status) {
                dispatch(changeStatusThemes(ApiStatus.FETCHED));
                CustomSnackbar("error", response.data.message || "");
                return false;
            }
            dispatch(changeStatusThemes(ApiStatus.FETCHED));
            CustomSnackbar("success", response.data.message || "");
            return true;
        } catch (error) {
            console.log(error);
            return dispatch(changeStatusThemes(ApiStatus.FETCHED));
        }
    };

    const setSelectedTheme = (theme: Theme) => {
        try {
            dispatch(onSetSelectedTheme(theme));
        } catch (error) {
            console.log(error);
        }
    };

    const setEditTheme = (theme: Theme) => {
        try {
            dispatch(onSetEditTheme(theme));
        } catch (error) {
            console.log(error);
        }
    };

    const setOpenModal = (status: boolean) => {
        try {
            dispatch(onSetOpenModal(status));
        } catch (error) {
            console.log(error);
        }
    };

    const changeIsEdit = (status: boolean) => {
        try {
            dispatch(onChangeIsEdit(status));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        //states
        courses,
        modules,
        openModal,
        isEdit,
        statusCourses,
        editCourse,
        selectedModule,
        statusModules,
        editModule,
        selectedCourse,
        editTheme,
        statusThemes,
        themes,
        //actions
        getCourses,
        getCoursesSearch,
        createCourseStore,
        editCourseStore,
        deleteCourse,
        setEditCourse,
        setOpenModal,
        setSelectedCourse,
        changeIsEdit,

        getModulesByCourse,
        createModuleStore,
        editModuleStore,
        deleteModule,
        setSelectedModule,
        setEditModule,

        getThemeByModule,
        createThemeStore,
        editThemeStore,
        deleteTheme,
        setSelectedTheme,
        setEditTheme
    };
};
