import { RoutesMap } from "../common/routes";

export enum Role {
  SUPER_ADMIN = 'Superadministrador',
  ADMIN       = 'Admin',
  SUPERVISOR  = 'Supervisor',
  MAINTENANCE = 'Jefe de Mantenimiento',
  DRIVER      = 'Conductor',
  MECHANIC    = 'Mecánico',
  ASSISTANT   = 'Ayudante'
}

export const ROUTES_FOR_ADMIN = [
  {
    module: RoutesMap.HOME,
    navigators: []
  },
]