export const ListItemIconStyle = {
    width: 22,
    height: 22,
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const ListMainItemButtonStyle = {
    height: 55,
    position: 'relative',
    textTransform: 'capitalize'
}

export const ListItemButtonStyle = {
    ...ListMainItemButtonStyle,
    paddingLeft: 5,
    fontWeight: 'fontWeightBold',
}
  