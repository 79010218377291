import { ApiStatus } from "../api/status"
import { Role } from "../roles/roleTypes"

export enum AuthStatus {
    LOGGING_IN = 'logging_in',
    VERIFYING = 'verifying',
    AUTHENTICATED = 'authenticated',
    NOT_AUTHENTICATED = 'not-authenticated'
}

export type UserType = {
    status: AuthStatus
    statusUser: ApiStatus
    user: User
    errorMessage: string |undefined
}

export type UserView =  {
    icon: string
    id: number
    idsmembership: string
    idviewType: number
    memerships: Membership[]
    name: string
    status: string
    url_event: string
    viewType: string
}

export type Membership = {
    id: number
    name: string
    status: string
    view_pay: string
    view_payName?: string
}

export type User = {

    id: number,
    rut: string,
    iduser_type: number,
    status_confirm: string,
    url_redirect: string|undefined,
    status: number,
    userType: Role | null,
    data?: UserData,
    views: UserView[] | undefined,
    modules: Module[] | undefined,
}

export type Module = {
    idmodule?: number
    module: string
    icon: string
    type_module: number
    view: ViewModule[]
}

export type ViewModule = {
    icon: string
    id: number
    name: string
    url: string
    type: number
}

export type UserData = {
    id?: number
    name?: string
    last_name?: string
    rut?: string
    date_birth?: string
    email?: string
    mail?: string
    address?: string
    idcommune?: number
    origin?: string
    photo?: string
    status?: string
}