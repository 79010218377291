import { Grid, Typography, styled, Link, GridProps } from "@mui/material";
import { BoxLogo } from "./BoxLogo";
import { BoxBody } from "./BoxBody";
import StudentCard from "./StudentCard";
import CertificateCard from "./CertificateCard";
import { MoreCourses, SearchForm } from "./SearchForm";
import { useStudentStore } from "../../../hooks/useStudentStore";
import { useCallback, useState } from "react";
import { ApiStatus } from "../../../types/api/status";
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop";
import { SocialSection } from "./SocialSection";
import { Footer } from "./Footer";

const GridBody = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: "white",
  [theme.breakpoints.up("md")]: { height: "100vh", overflow: "hidden scroll" },
}));

const GridContainer = styled(Grid)<GridProps>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    height: "100vh",
  },
  [theme.breakpoints.up("sm")]: {
    height: "100vh",
  },
}));

const Slogan = () => (
  <div
    className="slogan"
    style={{
      fontStyle: "italic",
      color: "#FFFFFF",
      textAlign: "center",
      marginTop: 30,
      fontSize: 18,
    }}
  >
    "Potenciamos tu éxito empresarial"
  </div>
);

const Certificates = ({ dataCertificate, onClick, onAnotherDNIClick }: any) => (
  <>
    <div
      style={{
        borderBottom: "1px dashed #D3D3D3",
        width: "100%",
        paddingBottom: 20,
        marginBottom: 20,
        textAlign: "center",
      }}
    >
      <Link style={{ cursor: "pointer" }} onClick={onAnotherDNIClick}>
        Buscar otro DNI {">"}
      </Link>
      <br />
    </div>
    <Typography
      variant="h6"
      gutterBottom
      style={{
        textAlign: "left",
        width: "90%",
        maxWidth: 750,
        marginBottom: 20,
      }}
    >
      {dataCertificate.length} Certificado
      {dataCertificate.length === 1 ? "" : "s"}:
    </Typography>

    {dataCertificate?.map((data: any, index: number) => {
      return (
        <CertificateCard
          key={index}
          data={data}
          onClick={onClick}
          baseColor={index % 2 === 0 ? "#002A6D" : "#0C46FF"}
        />
      );
    })}
    <br />
    <MoreCourses isSearched total={dataCertificate?.length || 0} />
    <br />
    <br />
  </>
);

export const CertificateLayout = () => {
  const {
    status: statusCertificate,
    getCertificateByDNI,
    getCertificatePDF,
    getCertificatePDFByURL,
  } = useStudentStore();
  const [dataCertificate, setDataCertificate] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleSearchCertificate = useCallback(
    async (dni: string): Promise<void> => {
      setLoading(true);
      if (!dni) return;
      const response = await getCertificateByDNI(dni);

      if (response.status || response.length > 0) {
        setDataCertificate(response);
      } else {
        setError(true);
      }
      setLoading(false);
    },
    [getCertificateByDNI, setLoading]
  );

  // TODO: Future feature
  const directDownload = useCallback((response: Blob) => {
    const nav: any = window.navigator;
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(response);
      return;
    }
    const data = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = "certificado.pdf";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }, []);

  const downloadCertificate = async (id: string) => {
    const response = await getCertificatePDFByURL(id);

    if (response !== false) {
      if (response?.detail) {
        const bloblURL = `${process.env.REACT_APP_ROOT_URL}/${response?.detail}`;
        const win = window.open(bloblURL, "_blank");
        win?.focus();
      } else {
        try {
          const response = await getCertificatePDF(id);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(response);
          link.download = "certificado.pdf";
          document.body.append(link);
          link.click();
          link.remove();
        } catch (e) {
          console.warn("No se pudo descargar. Volver a intentar.");
        }
      }
    }
  };

  const onAnotherDNIClick = useCallback(() => {
    setDataCertificate([]);
  }, [setDataCertificate]);

  return (
    <div style={{ flexGrow: 1 }}>
      {statusCertificate === ApiStatus.FETCHING && (
        <CustomBackdrop open={true} />
      )}
      <GridContainer container>
        <Grid
          item
          md={5}
          lg={4}
          xs={12}
          className="blue-block"
          style={{
            backgroundImage: "url('./FONDO_RAYAS.png')",
            backgroundColor: "#0C46FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <BoxLogo>
            <img
              className="logo"
              alt="logo focus"
              src="/LOGO_CFE_FULL.svg"
              width="100%"
            />
            {!loading && dataCertificate.length > 0 && (
              <StudentCard data={dataCertificate[0]} />
            )}
            <Slogan />
            <SocialSection />
          </BoxLogo>
        </Grid>
        <GridBody item md={7} lg={8} xs={12}>
          <BoxBody>
            {dataCertificate.length === 0 && (
              <>
                <SearchForm onClick={handleSearchCertificate} />
                <br />
                <br />

                <MoreCourses isSearched={error} total={0} />
                <br />
              </>
            )}
            {!loading && dataCertificate.length > 0 && (
              <Certificates
                dataCertificate={dataCertificate}
                onClick={downloadCertificate}
                onAnotherDNIClick={onAnotherDNIClick}
              />
            )}
            <Footer />
          </BoxBody>
        </GridBody>
      </GridContainer>
    </div>
  );
};
