import { useDispatch, useSelector } from "react-redux"
import { onOpenNavSection, selectNavSectionState } from "../redux/slices/navSectionSlice"

export const useNavSectionStore = () => {
   
    const dispatch = useDispatch()
    const { navOpen } = useSelector(selectNavSectionState)

    const openNavSection = (boolean: boolean) => {
        dispatch(onOpenNavSection(boolean))
    }

    return {
        //states
        navOpen,
        //actions
        openNavSection
    }
}