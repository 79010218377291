import { FC, useState, useEffect } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useCourseStore } from "../../hooks/useCourseStore";
import { Course, Module, Theme } from "../../types/slices/coursesType";
import { CreateEditModuleModal } from "./components/CreateEditModuleModal";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CreateEditThemeModal } from "./components/CreateEditThemeModal";

const columns = [
    { type: "options", field: "options", label: "Opciones" },
    { type: "text", field: "name", label: "NOMBRE" },
];

const Themes: FC = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [rowSelected, setRowSelected] = useState<Theme>({} as Theme);
    const [modalDeleteConfirm, setModalDeleteConfirm] =
        useState<boolean>(false);

    const {
        statusThemes,
        themes,
        selectedModule,
        openModal,
        getThemeByModule,
        setSelectedTheme,
        deleteTheme,
        setOpenModal,
        setEditTheme,
        changeIsEdit,
        setSelectedModule
    } = useCourseStore();

    useEffect(() => {
        if(selectedModule.id){
            getThemeByModule(selectedModule.id);
        }else{
            navigate(RoutesMap.MODULES_OF_COURSES)
        }
        
    }, [selectedModule]);

    const onAdd = () => {
        changeIsEdit(false);
        setOpenModal(true);
    };

    const onEdit = (rowSelected) => {
        changeIsEdit(true)
        setEditTheme(rowSelected)
        setOpenModal(true);
    };

    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected);
        setModalDeleteConfirm(true);
    };

    const onDeleteConfirm = async () => {
        const response = await deleteTheme(rowSelected?.id || 0);
        if (response === true) {
            setSelectedTheme({} as Theme);
            setModalDeleteConfirm(false);
            getThemeByModule(selectedModule.id);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const goBack = () => {
        setSelectedModule({} as Module);
        navigate(RoutesMap.MODULES_OF_COURSES);
    }

    return (
        <>
            { statusThemes === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            <Grid item xs={12} display='flex' alignItems='center' sx={{ marginLeft: '-25px' }}>
                <IconButton size="small" color="primary" aria-label="view" onClick={() => {goBack()}}>
                    <ArrowBackIcon fontSize='small' />
                </IconButton>
                <Typography>Volver</Typography>
            </Grid>
            <Grid item xs={12}>
                <CustomTable
                    title={selectedModule? "Temas del "+selectedModule.name: "Temas"}
                    columns={columns}
                    loading={loading}
                    rows={themes || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
            {modalDeleteConfirm && (
                <ModalConfirm
                    open={modalDeleteConfirm}
                    closeModal={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onCancel={() => {
                        setModalDeleteConfirm(false);
                    }}
                    onConfirm={onDeleteConfirm}
                    status={statusThemes}
                />
            )}
           {openModal && <CreateEditThemeModal/>}
        </>
    );
};

export default Themes;
