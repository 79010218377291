import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type navSectionType = {
    navOpen: boolean
}

const initialState: navSectionType = {
    navOpen: true
}

const navSectionSlice = createSlice({
    name: 'navSection',
    initialState,
    reducers: {
      onOpenNavSection (state, { payload }: { payload: boolean}) {
        state.navOpen    = payload
      }
    }
})

export const selectNavSectionState = (state: RootState) => state.navSectionSlice
export default navSectionSlice.reducer

export const {
    onOpenNavSection
} = navSectionSlice.actions