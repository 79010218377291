import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCommentIcon from "@mui/icons-material/AddComment";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import ArticleIcon from "@mui/icons-material/Article";
import BadgeIcon from "@mui/icons-material/Badge";
import BlockIcon from "@mui/icons-material/Block";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ChatIcon from "@mui/icons-material/Chat";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupIcon from "@mui/icons-material/Group";
import HailIcon from "@mui/icons-material/Hail";
import HandymanIcon from "@mui/icons-material/Handyman";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import SegmentIcon from "@mui/icons-material/Segment";
import SettingsIcon from "@mui/icons-material/Settings";
import ThreePIcon from "@mui/icons-material/ThreeP";
import TimelineIcon from "@mui/icons-material/Timeline";
import TodayIcon from "@mui/icons-material/Today";
import PersonIcon from "@mui/icons-material/Person";
import BookIcon from "@mui/icons-material/Book";
import SchoolIcon from "@mui/icons-material/School";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MediationSharpIcon from "@mui/icons-material/MediationSharp";
import { RoutesMap } from "../../types";
import { routeAdminType } from "../../types/typeComponents/NavSection";

export const icons = {
  AddCommentIcon,
  AddToQueueIcon,
  ArticleIcon,
  BadgeIcon,
  ChatIcon,
  ContentPasteIcon,
  DashboardIcon,
  EqualizerIcon,
  FormatListBulletedIcon,
  GroupIcon,
  HailIcon,
  HandymanIcon,
  PaidIcon,
  PeopleIcon,
  PeopleAltIcon,
  PermContactCalendarIcon,
  SegmentIcon,
  SettingsIcon,
  ThreePIcon,
  TimelineIcon,
  ManageAccountsIcon,
  AccountCircleIcon,
  NotificationsIcon,
  RememberMeIcon,
  CardMembershipIcon,
  TodayIcon,
  BlockIcon,
  ReceiptLongIcon,
};

const rutasAdmin: routeAdminType[] = [
  {
    id: 1,
    name: "Maestros",
    icon: <PersonIcon />,
    rutas: [
      {
        name_ruta: "Alumnos",
        location: RoutesMap.STUDENTS,
        icon: <AssignmentIndIcon />,
      },
      {
        name_ruta: "Programas",
        location: RoutesMap.PROGRAMS,
        icon: <BookIcon />,
      },
      {
        name_ruta: "Cursos",
        location: RoutesMap.COURSES,
        icon: <BookIcon />,
      },
      {
        name_ruta: "Ciclos",
        location: RoutesMap.CYCLE,
        icon: <SchoolIcon />,
      },
    ],
  },
  {
    id: 2,
    name: "Certificados",
    icon: <CardMembershipIcon />,
    rutas: [
      {
        name_ruta: "Certificados",
        location: RoutesMap.CERTIFICATES,
        icon: <CardMembershipIcon />,
      },
    ],
  },
];

export const navSections = {
  Admin: rutasAdmin,
};
