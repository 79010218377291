import { api } from "./configs/axiosConfigs";

export const CourseAPI = {

    //courses
    getCourses: async () => {
        const response = await api
            .get("/course")
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    getCoursesSearch: async (term) => {
        const response = await api
            .get(`/course?term=${term}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createCourse: async (data: any) => {
        const response = await api
            .post("/course", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editCourse: async (id: number, data: any) => {
        const response = await api
            .patch(`/course/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteCourse: async (id: number) => {
        const response = await api
            .delete(`/course/${id}?status=2`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    //modules
    getModulesByCourse: async (idcourse) => {
        const response = await api
            .get(`/module/${idcourse}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createModule: async (data: any) => {
        const response = await api
            .post("/module", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editModule: async (id: number, data: any) => {
        const response = await api
            .patch(`/module/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteModules: async (id: number) => {
        const response = await api
            .delete(`/module/${id}?status=2`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

    //tema

    getThemeByModule: async (idmodule) => {
        const response = await api
            .get(`/topics/${idmodule}`)
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    createTheme: async (data: any) => {
        const response = await api
            .post("/topics", { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    editTheme: async (id: number, data: any) => {
        const response = await api
            .patch(`/topics/${id}`, { ...data })
            .then((response) => response.data)
            .catch((error) => {
                console.error(error);
                return error.response.data;
            });
        return response;
    },

    deleteTheme: async (id: number) => {
        const response = await api
            .delete(`/topics/${id}?status=2`)
            .then((response) => response.data)
            .catch((error) => {
                return error.response.data;
            });
        return response;
    },

};
