export enum RoutesMap {
  HOME = "/home",
  LOGIN = "/login",
  MAIN = "/main",
  MY_PROFILE = "/my-profile",

  /* ------------------------- VIEWS ADMIN ------------------------------- */

  STUDENTS = "/alumnos",
  PROGRAMS = "/programas",
  COURSES = "/cursos",
  MODULES_OF_COURSES = "/modulos-cursos",
  THEMES_OF_COURSES = "/temas-cursos",
  CYCLE = "/ciclos",
  ALUMNOS_OF_CYCLE = "/alumnos-ciclo",
  CERTIFICATES = "/certificados",
}
