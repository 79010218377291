import { FC, useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useSelector } from "react-redux";
import { Student } from "../../types/slices/studentType";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useCycleStore } from "../../hooks/useCycleStore";
import { Cycle } from "../../types/slices/cycleType";
import { CreateEditCycleModal } from "./components/CreateEditCycleModal";
import { RoutesMap } from "../../types";

const columns = [
  { type: "options", field: "options", label: "Opciones" },
  {
    type: "text",
    field: "program",
    label: "PROGRAMA",
    format: (row) => row.programs?.name ?? "",
  },
  { type: "text", field: "name", label: "NOMBRE" },
  {
    type: "text",
    field: "teacher",
    label: "PROFESOR",
    format: (row) => row.teachers.name,
  },
  {
    type: "text",
    field: "course",
    label: "CURSO",
    format: (row) => row.courses.name,
  },
  { type: "text", field: "date_start", label: "FECHA INICIO" },
  { type: "text", field: "date_end", label: "FECHA FIN" },
  { type: "detail", field: "", label: "" },
];

const CycleView: FC = () => {
  const navigate = useNavigate();
  const { search } = useSelector(selectHeaderState);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowSelected, setRowSelected] = useState<Student>({} as Student);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [filteredCycles, setFilteredCycles] = useState<Cycle[]>([]);

  const {
    cycles,
    status: cycleStatus,
    openModal,
    getCycle,
    setSelectedCycle,
    deleteCycle,
    setOpenModal,
    setEditCycle,
    changeIsEdit,
  } = useCycleStore();

  useEffect(() => {
    getCycle();
  }, []);

  const onDetails = (rowSelected) => {
    setSelectedCycle(rowSelected);
    navigate(RoutesMap.ALUMNOS_OF_CYCLE);
  };

  const onAdd = () => {
    changeIsEdit(false);
    setOpenModal(true);
  };

  const onEdit = (rowSelected) => {
    changeIsEdit(true);
    setEditCycle(rowSelected);
    setOpenModal(true);
  };

  const onDelete = (rowSelected) => {
    setRowSelected(rowSelected);
    setModalDeleteConfirm(true);
  };

  const onDeleteConfirm = async () => {
    const response = await deleteCycle(rowSelected?.id || 0);
    if (response === true) {
      setSelectedCycle({} as Cycle);
      setModalDeleteConfirm(false);
      getCycle();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSearch = useCallback(
    (program) => {
      setFilteredCycles(() => {
        if (!program) return cycles;

        return cycles.filter(({ idprogram }) => idprogram === program.id);
      });
    },
    [cycles, setFilteredCycles]
  );

  useEffect(() => {
    if (!filteredCycles.length || cycles.length) {
      setFilteredCycles(cycles);
    }
  }, [cycles]);

  return (
    <>
      {cycleStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
      <Grid item xs={12}>
        <CustomTable
          title={"Ciclos"}
          columns={columns}
          loading={loading}
          rows={filteredCycles || []}
          onRowClick={() => {}}
          hasOptions
          onSearchByOption={onSearch}
          onAddFn={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          onDetails={onDetails}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
      {modalDeleteConfirm && (
        <ModalConfirm
          open={modalDeleteConfirm}
          closeModal={() => {
            setModalDeleteConfirm(false);
          }}
          onCancel={() => {
            setModalDeleteConfirm(false);
          }}
          onConfirm={onDeleteConfirm}
          status={cycleStatus}
        />
      )}
      {openModal && <CreateEditCycleModal />}
    </>
  );
};

export default CycleView;
