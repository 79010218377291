import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    counter: 5,
}

export const counterSlice = createSlice({
    name: 'counterslice',
    initialState,
    reducers : {
        setCounter: (state, action) => {
            state.counter = action.payload.counter;
        }
    }
})

export const {setCounter} = counterSlice.actions;
export default counterSlice.reducer;