import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BoxBody = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flex: 1,
  minWidth: "min-content",
  overflow: "auto",
  h4: {
    color: "#0C46FF",
    fontSize: 48,
    marginBottom: 10,
  },
  [theme.breakpoints.down("md")]: {
    padding: 30,
    paddingLeft: 20,
    paddingRight: 20,
    h4: {
      fontSize: 40,
    },
  },
  [theme.breakpoints.up("md")]: {
    margin: 35,
    minHeight: "92vh",
    justifyContent: "center",
  },
}));
