import { api } from "./configs/axiosConfigs"

export const AuthAPI = {

  login: async (iduser_type: number, password: string, dni: string  ) => {
    const response = await api.post('/auth/login',
      {
        dni,
        password,
        iduser_type,
      }
    )
    .then(response => response.data.data)
    .catch((error) => {
        return error.response.data
    })

    return response
  },

  refresh: async (token: string) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await api.post(
      '/auth/refresh',
    ).then(response => response.data.data)
      .catch((error) => {
        console.error(error)
      })
    return response
  },

   verifyToken: async (token) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const response = await api.get(
      'auth/validateToken',
    ).then(response => {
      console.log(response)
      return response.data
    })
      .catch((error) => {
        console.error(error)
      })
    return response
  },

  logout: async () => {
    const response = await api.post(
      '/auth/logout'
    ).then(response => response.data.data)
      .catch((error) => {
        return error.response.data
      })
    return response
  },

  changePassword: async (data: any) => {
    const response = await api.patch(
        '/user/changePassword', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },
}