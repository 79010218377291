import { Link } from "@mui/material";
import { FC } from "react";

export const Footer: FC = () => {
  return (
    <>
      <Link
        style={{ cursor: "pointer" }}
        href="https://forms.gle/8hNm8dZUCCZX6Tp38 "
        target="_blank"
      >
        Libro de reclamaciones {">"}
      </Link>
      <Link
        style={{ cursor: "pointer" }}
        href="https://drive.google.com/file/d/15dDon5Oohnqtmd0hpUmM9mScOD9r_nq0/view"
        target="_blank"
      >
        Reglamento del estudiante {">"}
      </Link>
    </>
  );
};
