import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Program, programsType } from "../../types/slices/programsType";

const initialState: programsType = {
  statusPrograms: ApiStatus.FETCHED,
  programs: [],
  errorMessage: undefined,
  editProgram: {} as Program,
  selectedProgram: {} as Program,
  isEdit: false,
  openModal: false,
};

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    onFetchPrograms(state, { payload }: { payload: Program[] }) {
      state.statusPrograms = ApiStatus.FETCHED;
      state.programs = payload;
      state.errorMessage = undefined;
    },
    changeStatusPrograms(state, { payload }: { payload: ApiStatus }) {
      state.statusPrograms = payload;
    },
    onSetEditProgram(state, { payload }: { payload: Program }) {
      state.editProgram = payload;
    },
    onSetSelectedProgram(state, { payload }: { payload: Program }) {
      state.selectedProgram = payload;
    },
    onSetOpenModal(state, { payload }: { payload: boolean }) {
      state.openModal = payload;
    },
    onChangeIsEdit(state, { payload }: { payload: boolean }) {
      state.isEdit = payload;
    },
  },
});

export const selectProgramState = (state: RootState) => state.programSlice;
export default programSlice.reducer;

export const {
  onFetchPrograms,
  changeStatusPrograms,
  onSetEditProgram,
  onSetSelectedProgram,
  onSetOpenModal,
  onChangeIsEdit,
} = programSlice.actions;
